import React, { useState } from "react"

import PodPosture from "../assets/img/Not Evil Ads Example Cross Promotion PodPosture.png"
import DarkNoise from "../assets/img/Not Evil Ads Example Cross Promotion Dark Noise.png"
import HealthAutoExport from "../assets/img/Not Evil Ads Example Cross Promotion Health Auto Export.png"
import AppTelemetry from "../assets/img/Not Evil Ads Example Cross Promotion AppTelemetry.png"
import Snapshot from "../assets/img/Rotato Snapshot.png"

import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import SEO from "../components/SEO"

function App() {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = async e => {
    e.preventDefault()
    var input = document.getElementById("email").value
    if (input !== "you@awesome.com" || input.lengh > 0) {
      console.log(input)
      setOpen(!isOpen)
      // POST request using fetch with async/await
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          emailAddress: input,
        }),
      }
      console.log(requestOptions)
      const response = await fetch(
        // "http://localhost:3000/static/register",
        "https://api.notevilads.com/email-signup/",
        requestOptions
      )
      // const data = await response.json()
      const data = response
      console.log(data)
      // this.setState({ postId: data.id })
      window.location.replace("/confirmation")
    }
  }

  const coinflip = Math.random()
  const image = coinflip < 0.5 ? DarkNoise : PodPosture
  const PodPostureAltText =
    "Not Evil Ad running at the top of a blurred music player interface. Ad shows the Pod Posture app icon next to text saying PodPosture improves your posture with AirPods Pro, get better posture today!"
  const DarkNoiseAltText =
    "Not Evil Ad running at the top of a blurred music player interface. Ad shows the Dark Noise app icon next to text saying Simple. Fast. White noise refined."
  const imageAltText = coinflip < 0.5 ? DarkNoiseAltText : PodPostureAltText

  return (
    <div>
      <SEO />

      <Navbar />
      <main>
        <div className="container">
          <section className="hero row align-items-center">
            <div className="col-lg-6">
              <h1>Grow your userbase</h1>
              <p className="my-3">
                Join our beta to grow your app audience through app to app cross
                promotion.
              </p>
              <p className="my-3">
                When we launch, sell ad space in your apps.
              </p>
              <div className="col-6 mx-auto mx-lg-0">
                <a href="./#signup" className="btn btn-primary my-3">
                  Sign up for the beta!
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={image}
                alt={imageAltText}
                className="mx-auto d-block"
                style={{ width: "80%" }}
              />
            </div>
          </section>
        </div>

        <div className="container-fluid personas-bg">
          <div className="container">
            <section className="personas row align-items-center">
              <div className="personas row align-items-center">
                <div className="col-lg-4 mx-auto">
                  <img src={HealthAutoExport} alt="React Logo" />
                </div>
                <div className="col-lg-8 mx-auto">
                  <h6>App monetisation</h6>
                  <h3>Why should I put ads in my app?</h3>
                  <br />
                  <p>
                    Monetising indie app development is hard. Doing so without
                    compromising user privacy is even harder.
                  </p>
                  <p>
                    Not Evil Ads will let you sell advertising space in your
                    apps, on your terms.
                  </p>
                  <p>
                    You can then offer a free tier with no ads, an ad supported
                    tier and a pro tier for your power users and get the best of
                    both worlds.
                  </p>
                </div>
              </div>
              <div className="personas row align-items-center d-flex">
                <div className="col-lg-4 mx-auto order-lg-1">
                  <img src={AppTelemetry} alt="React Logo" />
                </div>
                <div className="col-lg-8 mx-auto order-lg-0">
                  <h6>Cross promotion network</h6>
                  <h3>Grow your userbase with cross promotion</h3>
                  <br />
                  <p>
                    While in beta, the platform will serve as a cross promotion
                    network with apps advertising each other.
                  </p>
                  <p>
                    This will help you grow your userbase through exposure to
                    potential new users as well as incentivise your power users
                    to go pro.
                  </p>
                  <p>
                    Create an ad for your app, turn on cross promotion and your
                    ad will appear in others apps and others ads will appear in
                    your own app.
                  </p>
                </div>
              </div>
              <div className="personas row align-items-center">
                <div className="col-lg-6 mx-auto">
                  <img src={Snapshot} alt="React Logo" />
                </div>
                <div className="col-lg-6 mx-auto">
                  <h6>Ad Analytics</h6>
                  <h3>See how many people you are reaching</h3>
                  <p>
                    Privacy focussed advertising means that we don't aggregate
                    user data.
                  </p>
                  <p>
                    Instead we show anonymised usage data giving only high level
                    breakdowns.
                  </p>
                  <p>
                    In future this will be used to allow you to sell advertising
                    based on your app content and usage!
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section id="signup" className="row cta">
          <div className="col-12 text-center ">
            <h2>
              Help build{" "}
              <span className="text-primary font-weight-bold">better</span>{" "}
              advertising.
            </h2>

            <p>
              Sign up for the Not Evil Ads beta and get notified when we launch.
            </p>

            {/* <div className="mx-auto mx-lg-0">
              <a href="https://app.notevil.co" className="btn btn-primary my-3">
                Sign up for the beta!
              </a>
            </div> */}

            <form onSubmit={toggleOpen}>
              <div className="col-lg-4 mx-auto">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="you@awesome.com"
                    id="email"
                    aria-label="email"
                  />
                  <span className="input-group-btn">
                    <div className="btn-group">
                      <button type="submit" class="btn btn-primary">
                        Sign up
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  )
}
export default App
